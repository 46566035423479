import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import {
  SidebarModule,
  MenuAllModule,
  TreeViewAllModule,
  ToolbarAllModule
} from '@syncfusion/ej2-angular-navigations';

import { ProtectedRouteComponent } from './protected-route.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from 'src/app/shared';
import { HelpSidebarComponent } from './help-sidebar/help-sidebar.component';

@NgModule({
  declarations: [
    ProtectedRouteComponent,
    NavbarComponent,
    AppSidebarComponent,
    HelpSidebarComponent
  ],
  imports: [
    RouterModule.forChild([]),
    SharedModule,
    DropDownButtonModule,
    SidebarModule,
    MenuAllModule,
    TreeViewAllModule,
    ToolbarAllModule
  ],
  exports: [ProtectedRouteComponent]
})
export class ProtectedRouteModule {}
