import { Component, Input, OnInit } from '@angular/core';
import { UserDTO, UserService } from 'booking-sdk';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-info-block',
  templateUrl: './user-info-block.component.html',
  styleUrls: ['./user-info-block.component.scss']
})
export class UserInfoBlockComponent implements OnInit {
  @Input()
  userId: number;

  user: UserDTO;
  private onDestroy$ = new Subject();

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getUser(this.userId);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getUser(userId: number) {
    this.userService
      .getUsers(
        0,
        1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        userId
      )
      .pipe(
        takeUntil(this.onDestroy$),
        map(pageUserDTO => pageUserDTO.content[0])
      )
      .subscribe(user => {
        this.user = user;
      });
  }

  formatUsername(username: string): string {
    return username.slice(0, 8) + '-' + username.slice(8);
  }
}
