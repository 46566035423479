import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProtectedRoutingModule } from './protected-routing.module';
import { ProtectedRouteModule } from './protected-route';

@NgModule({
  imports: [CommonModule, ProtectedRouteModule, ProtectedRoutingModule],
  declarations: [
  ]
})
export class ProtectedModule {}
