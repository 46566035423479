<ejs-dialog
  #EditSMSDialog
  [position]="smsDialogPosition"
  [showCloseIcon]="false"
  [visible]="false"
  [closeOnEscape]="false"
  header="Skicka SMS"
  width="400px"
  [buttons]="buttons"
>
  <app-show-sms
    [hidden]="!EditSMSDialog.visible"
    [smsBody]="_smsBody"
    (smsBodyChange)="updateSmsBody($event)"
  ></app-show-sms>
</ejs-dialog>
