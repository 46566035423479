import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Dialog,
  DialogComponent,
  DialogUtility,
  PositionDataModel
} from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-sms-confirm',
  templateUrl: './sms-confirm.component.html',
  styleUrls: ['./sms-confirm.component.scss']
})
export class SmsConfirmComponent implements OnInit {
  @Input() set smsBody(s: string) {
    if (s) {
      // console.log("s 1", s);
      s = s.replace('&nbsp;&nbsp; << Dold för kund >>', '');
      // console.log("s 2", s);
    }

    this._smsBody = s;

    if (s) {
      // Replace <br> with \n
      this.customSMSBody = s.replace(/<br>/g, '\n');
    }
  }

  // 197411017978

  _smsBody: string;

  @Output()
  onAction = new EventEmitter<SmsConfirmAction>();

  @ViewChild('EditSMSDialog') editSMSDialog: DialogComponent;
  smsDialogPosition: PositionDataModel = { X: 'center', Y: 'top' };

  buttons: Object = [
    {
      click: this.onSendSMS.bind(this),
      // Accessing button component properties by buttonModel property
      buttonModel: {
        content: 'Skicka SMS',
        // Enables the primary button
        isPrimary: true
      }
    },
    {
      click: this.onCancel.bind(this),
      buttonModel: {
        content: 'Skicka inte'
      }
    }
  ];

  smsConfirmDialog: Dialog;
  customSMSBody: string;

  constructor() {}

  ngOnInit(): void {}

  public confirmSMS(text?: string) {
    this.smsConfirmDialog = DialogUtility.confirm({
      title: 'Bokningen är sparad',
      content: text ? text : 'Vill du skicka ett SMS till kunden?',
      cancelButton: { text: 'Nej', click: this.hideConfirmDialog.bind(this) },
      okButton: { text: 'Ja', click: this.showSMSEditDialog.bind(this) },
      position: this.smsDialogPosition
    });
  }

  hideConfirmDialog() {
    this.smsConfirmDialog.hide();
    this.onAction.emit({ action: 'continue' });
  }

  showSMSEditDialog() {
    this.smsConfirmDialog.hide();
    this.editSMSDialog.show();
  }

  updateSmsBody(smsBody: string) {
    this.customSMSBody = smsBody;
  }

  onSendSMS() {
    this.editSMSDialog.hide();
    this.onAction.emit({ action: 'continue', smsBody: this.customSMSBody });
  }

  onCancel() {
    this.editSMSDialog.hide();
    this.onAction.emit({ action: 'cancel' });
  }
}

export interface SmsConfirmAction {
  action: 'continue' | 'cancel';
  smsBody?: string;
}
