<label for="Advisor">Rådgivare</label>
<ejs-dropdownlist
  id="Advisor"
  name="Advisor"
  class="e-dropdownlist e-field"
  [readonly]="true"
  [enabled]="false"
  [dataSource]="currentAdvisors"
  [fields]="advisorFields"
  [(value)]="currentAdvisorId"
  (change)="onAdvisorChange($event)"
></ejs-dropdownlist>
