<h1>Lägg till kund</h1>

<div *ngIf="existingUser" class="observation">
  <div class="obs-header">OBS!</div>
  <div class="obs-body">Denna användare redan finns i systemet,</div>
  <div class="obs-body">men du kan lägga till dem som kund.</div>
</div>

<form [formGroup]="form" fxLayout="column" fxLayoutGap="25px" (ngSubmit)="onSubmit()">
  <div *ngIf="!isSaving && !isLoading" class="inputs" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25" fxFlexOffset="10" class="e-text" for="firstname">Förnamn *</label>
      <input
        class="e-input"
        [class.e-error]="form.get('firstname').invalid && form.get('firstname').touched"
        type="text"
        id="firstname"
        formControlName="firstname"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />
      <div
        fxFlexOffset="5"
        *ngIf="form.get('firstname').invalid && form.get('firstname').touched"
        class="error-message"
      >
        Lägg till förnamn
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25" fxFlexOffset="10" class="e-text" for="lastname">Efternamn *</label>
      <input
        class="e-input"
        [class.e-error]="form.get('lastname').invalid && form.get('lastname').touched"
        type="text"
        id="lastname"
        formControlName="lastname"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />
      <div
        fxFlexOffset="5"
        *ngIf="form.get('lastname').invalid && form.get('lastname').touched"
        class="error-message"
      >
        Lägg till efternamn
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25" fxFlexOffset="10" class="e-text" for="username">Personnummer *</label>
      <input
        class="e-input"
        [class.e-error]="form.get('username').invalid && form.get('username').touched"
        type="text"
        mask="00000000-0000"
        maxlength="13"
        id="username"
        placeholder="ÅÅÅÅMMDD-XXXX"
        formControlName="username"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />
      <div *ngIf="isIncorrectSSNLength()" class="error-message">
        Personnummer måsta bli 12 siffror
      </div>
      <div *ngIf="isInvalidSSN()" class="error-message">Fel personnummer</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25" fxFlexOffset="10" class="e-text" for="phone">Telefon *</label>
      <input
        class="e-input"
        [class.e-error]="form.get('phone').invalid && form.get('phone').touched"
        type="text"
        id="phone"
        formControlName="phone"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />
      <div
        fxFlexOffset="5"
        *ngIf="form.get('phone').invalid && form.get('phone').touched"
        class="error-message"
      >
        Lägg till ett korrekt telefonnummer
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <label fxFlex="25" fxFlexOffset="10" class="e-text" for="email">E-post</label>
      <input
        class="e-input"
        [class.e-error]="form.get('email').invalid && form.get('email').touched"
        type="text"
        id="email"
        formControlName="email"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />
      <div
        fxFlexOffset="5"
        *ngIf="form.get('email').invalid && form.get('email').touched"
        class="error-message"
      >
        Lägg till en korrekt E-postadress
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
    <button ejs-button (click)="onCancel()" type="button" [disabled]="isSaving">Avbryt</button>
    <button ejs-button isPrimary="true" type="submit" [disabled]="isSaving">
      {{ isSaving ? 'Sparar...' : 'Spara' }}
    </button>
  </div>
</form>

<ejs-toast #Toast [position]="toastPosition">
  <ng-template #title>
    <div></div>
  </ng-template>
  <ng-template #content>
    <div></div>
  </ng-template>
</ejs-toast>

<!-- <h1>Lägg till användare</h1>

<form [formGroup]="form" fxLayout="column" fxLayoutGap="25px" (ngSubmit)="onSubmit()">
  <div *ngIf="!isSaving && !isLoading" class="inputs" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column">
      <label class="e-text" for="firstname">Förnamn</label>
      <input
        class="e-input"
        [class.e-error]="form.get('firstname').invalid && form.get('firstname').touched"
        type="text"
        id="firstname"
        formControlName="firstname"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />

      <div
        *ngIf="form.get('firstname').invalid && form.get('firstname').touched"
        class="error-message"
      >
        Vänligen ange ett förnamn.
      </div>
    </div>

    <div fxLayout="column">
      <label class="e-text" for="lastname">Efternamn</label>
      <input
        class="e-input"
        [class.e-error]="form.get('lastname').invalid && form.get('lastname').touched"
        type="text"
        id="lastname"
        formControlName="lastname"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />

      <div
        *ngIf="form.get('lastname').invalid && form.get('lastname').touched"
        class="error-message"
      >
        Vänligen ange ett efternamn.
      </div>
    </div>

    <div fxLayout="column">
      <label class="e-text" for="username">Personnummer</label>
      <input
        class="e-input"
        [class.e-error]="form.get('username').invalid && form.get('username').touched"
        type="text"
        mask="00000000-0000"
        maxlength="13"
        id="username"
        placeholder="ÅÅÅÅMMDD-XXXX"
        formControlName="username"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        autocomplete="off"
      />

      <div *ngIf="isIncorrectSSNLength()" class="error-message">
        Vänligen ange personnummer med 12 siffror.
      </div>

      <div *ngIf="isInvalidSSN()" class="error-message">
        Du har angett ett felaktigt personnummer, vänligen försök igen.
      </div>
    </div> -->

<!-- <div fxLayout="column">
      <label class="e-text" for="roles">Roller</label>
      <ejs-multiselect
        formControlName="roles"
        name="roles"
        class="e-error"
        [class.e-error]="form.get('roles').invalid && form.get('roles').touched"
        [dataSource]="roles"
        [fields]="roleFields"
        placeholder="Roller"
      >
      </ejs-multiselect> -->

<!-- <div *ngIf="form.get('roles').invalid && form.get('roles').touched" class="error-message">
        Vänligen välj minst en roll
      </div> -->
<!-- </div> -->

<!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <label class="checkbox-label" for="active" fxFlexOrder="2">Aktiv</label>
      <input type="checkbox" id="active" formControlName="active" />
    </div> -->
<!-- </div> -->

<!-- <div *ngIf="isSaving || isLoading" fxLayout="column">
    <ejs-progressbar
      fxFlexAlign="center"
      type="Circular"
      [maximum]="100"
      [minimum]="0"
      [value]="20"
      width="60px"
      height="60px"
      isIndeterminate="true"
      progressColor="#31a3ae"
    >
    </ejs-progressbar>
  </div> -->

<!-- <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
    <button ejs-button (click)="onCancel()" type="button">Avbryt</button>
    <button ejs-button isPrimary="true" type="submit" [disabled]="isSaving || isLoading">
      {{ isSaving ? 'Sparar...' : 'Spara' }}
    </button>
  </div>
</form> -->
