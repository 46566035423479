export const SubjectColor = {
  1: '#cb6bb2',
  2: '#56ca85',
  3: '#6e6a6a',
  4: '#f8a398',
  6: '#7499e1',
  7: '#9c605c',
  9: '#6580a3',
  10: '#6da390'
};
