<!-- <h1>SMS</h1> -->

<div>
  <ejs-richtexteditor
    #showSMS
    id="showSMS"
    name="showSMS"
    [(value)]="smsBody"
    [enableResize]="true"
    [toolbarSettings]="editorToolbarSettings"
    [cssClass]="cssClass"
    (change)="updateSmsBody($event)"
    [saveInterval]="500"
  ></ejs-richtexteditor>
</div>

<!-- 197411017978 -->
