import { Component } from '@angular/core';
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import locale from '@syncfusion/ej2-locale/src/sv.json';
import Numbers from 'cldr-data/main/sv/numbers.json';
import CaGregorian from 'cldr-data/main/sv/ca-gregorian.json';
import NumberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import TimeZoneNames from 'cldr-data/main/sv/timeZoneNames.json';
import { User } from 'booking-sdk';

// en-US
// sv-SE

loadCldr(Numbers, CaGregorian, NumberingSystems, TimeZoneNames);
setCulture('sv');
L10n.load({
  sv: {
    ...locale.sv,
    schedule: {
      ...locale.sv.schedule,
      cancelButton: 'Avbryt',
      deleteEvent: 'Den här händelsen',
      editEvent: 'Den här händelsen',
      newEvent: 'Ny händelse'
    },
    richtexteditor: {
      ...locale.sv.richtexteditor,
      createLink: 'Lägg till länk',
      linkText: 'Länk text',
      linkTooltipLabel: 'Länk beskrivning',
      linkTitle: 'Ange en beskrivning',
      dialogInsert: 'Spara',
      dialogUpdate: 'Spara',
      dialogCancel: 'Avbryt'
    },
    grid: {
      ClearButton: 'Avbryt',
      Columnchooser: 'Kolumner',
      ChooseColumns: 'Välj kolumn',
      Search: 'Sök',
      SelectAll: 'Välj alla',
      OKButton: 'OK',
      CancelButton: 'Avbryt',
      EmptyRecord: 'Inga poster att visa',
      Matchs: 'Inga matchningar funna',
      NoResult: 'Inga matchningar funna'
    }
  }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {}
