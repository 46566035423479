import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-next-available-block',
  templateUrl: './next-available-block.component.html',
  styleUrls: ['./next-available-block.component.scss']
})
export class NextAvailableBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
