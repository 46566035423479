import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BreakPointRegistry } from '@angular/flex-layout';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScheduleTypeDTO } from 'booking-sdk';

@Component({
  selector: 'app-schedule-type-selector',
  templateUrl: './schedule-type-selector.component.html',
  styleUrls: ['./schedule-type-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ScheduleTypeSelectorComponent
    }
  ]
})
export class ScheduleTypeSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  scheduleTypes: ScheduleTypeDTO[];

  @Input()
  scheduleType: ScheduleTypeDTO;

  // @Output()
  // valueChange: EventEmitter<LocationDTO> = new EventEmitter<LocationDTO>();
  onChange = schedule => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  scheduleTypeFields: Object = { text: 'name', value: 'scheduleTypeId' };
  currentScheduleTypeId: number;
  currentScheduleTypes: ScheduleTypeDTO[];

  constructor() {}

  writeValue(scheudleType: ScheduleTypeDTO) {
    this.scheduleType = scheudleType;
    if (this.scheduleTypes && this.scheduleType) {
      this.updateList();
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scheduleTypes && changes.scheduleTypes.currentValue) {
      this.updateList();
    }
  }

  private updateList() {
    // Copy original list
    this.currentScheduleTypes = JSON.parse(JSON.stringify(this.scheduleTypes));

    if (this.scheduleType) {
      this.currentScheduleTypeId = this.scheduleType.scheduleTypeId;

      // Add scheduleType from schedule to list, if it doesn't already exists
      if (
        !this.currentScheduleTypes.find(s => s.scheduleTypeId === this.scheduleType?.scheduleTypeId)
      ) {
        this.currentScheduleTypes.push(this.scheduleType);
      }
    }
  }

  onScheduleTypeChange(args) {
    // this.valueChange.emit(args.itemData);
    this.markAsTouched();
    this.scheduleType = args.itemData;
    this.onChange(this.scheduleType);
  }
}
