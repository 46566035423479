<div class="root" fxLayout="column">
  <app-navbar
    [content]="content"
    [showHelp]="showHelp"
    [isMobileDevice]="isMobileDevice"
    (showHelpChanged)="showHelpChanged($event)"
  ></app-navbar>

  <div *ngIf="isMobileDevice" class="content" fxFlex="auto" fxLayout="row" fxFlexFill>
    <div fxFlex="auto">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div *ngIf="!isMobileDevice" class="content" fxFlex="auto" fxLayout="row" fxFlexFill>
    <!-- fxFlex="235px" -->
    <div id="sidebarComp" class="content-undocked">
      <app-sidebar
        (contentChanged)="contentChanged($event)"
        (toggleDocking)="toggledDocking($event)"
      ></app-sidebar>
    </div>
    <div fxFlex="auto">
      <router-outlet></router-outlet>
    </div>
    <!-- SRD 22-05-23 -->
    <div class="help-sidebar-wrapper" [class.visible]="showHelp" fxFlex="400px">
      <app-help-sidebar
        [content]="content"
        [showHelp]="showHelp"
        (showHelpChanged)="showHelpChanged($event)"
      ></app-help-sidebar>
    </div>
  </div>
</div>
