import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { UserDTO } from 'booking-sdk';

import { AuthService } from 'src/app/core/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: UserDTO;

  @Input()
  content: string;

  @Input()
  showHelp: boolean;

  @Input()
  isMobileDevice: boolean;

  @Output('showHelpChanged')
  showHelpChanged = new EventEmitter<boolean>();

  dropdownItems: ItemModel[] = [
    {
      text: 'Logga ut',
      iconCss: 'e-ddb-icons e-logout'
    }
  ];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.user = this.authService.getUser();
  }

  onSelect(args: MenuEventArgs): void {
    if (args.item.text === this.dropdownItems[0].text) {
      this.authService.logout();
    }
  }

  toggleHelpSidebar() {
    this.showHelp = !this.showHelp;
    this.showHelpChanged.emit(this.showHelp);
  }
}
