import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ComboBoxComponent,
  DropDownListComponent,
  FilteringEventArgs
} from '@syncfusion/ej2-angular-dropdowns';
import { ToastComponent, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import {
  LocationDTO,
  LocationService,
  SubjectDTO,
  SubjectService,
  UserDTO,
  UserService
} from 'booking-sdk';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { EmitType } from '@syncfusion/ej2-base';
import { isValidSsn } from 'se-ssn';

export interface CustomerSelection {
  customer: UserDTO;
  subject: SubjectDTO;
  location: LocationDTO;
}

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {
  @Input()
  customerUpdated: UserDTO;

  @Output()
  customerChange: EventEmitter<CustomerSelection> = new EventEmitter<CustomerSelection>();
  @Output()
  action: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('Toast') toastComponent: ToastComponent;
  @ViewChild('cbcustomer') customerCBObj: ComboBoxComponent;
  @ViewChild('ddlsubject') subjectDDLObject: DropDownListComponent;
  @ViewChild('ddllocation') locationDDLObject: DropDownListComponent;
  @ViewChild('CreateClientDialog') createClientDialog: DialogComponent;
  @ViewChild('UpdateClientDialog') updateClientDialog: DialogComponent;

  private onDestroy$ = new Subject();
  customers: UserDTO[];
  subjects: SubjectDTO[] = [];
  locations: LocationDTO[];
  isLoadingCustomers: boolean;
  isLoadingSubjects: boolean = false;
  isLoadingLocations: boolean = false;
  invalidSSN: boolean = true;
  ssn: string = '';
  customerExists: boolean = true;
  selCustomer: UserDTO;
  selLocation: LocationDTO;
  selSubject: SubjectDTO;
  dialogPosition: PositionDataModel = { X: 'center', Y: 'center' };
  toastPosition: ToastPositionModel = { X: 'center', Y: 'bottom' };
  infoToastPosition: ToastPositionModel = { X: 'center', Y: 'top' };
  customerFields: Object = { text: 'PNName', value: 'userId' };
  locationFields: Object = { text: 'label', value: 'locationId' };
  subjectFields: Object = { groupBy: 'group', text: 'label', value: 'subjectId' };

  nonLocation: LocationDTO = {
    locationId: -1,
    label: 'Digitalt',
    orderBy: 0,
    active: false
  };

  nonSubject: SubjectDTO = {
    subjectId: -1,
    label: 'Alla',
    orderBy: 0,
    active: false,
    groupHeader: false
  };

  constructor(
    private userService: UserService,
    private subjectService: SubjectService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.getSubjects();
    this.getLocations();

    this.emitCustomerChange();
    // this.locationDDLObject.addItem(
    //   { label: this.nonLocation.label, locationId: this.nonLocation.locationId },
    //   0
    // );
  }

  ngOnChanges(): void {
    // console.log('ngOnChanges - this.customerUpdated ', this.customerUpdated);

    if (this.customerUpdated) {
      this.getCustomers();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  emitCustomerChange() {
    // console.log('this.selCustomer ', this.selCustomer);
    this.customerChange.emit({
      customer: this.selCustomer,
      subject: this.selSubject,
      location: this.selLocation
    });
  }

  createClient(): void {
    this.createClientDialog.show();
  }

  onClientCreated(createdUser: UserDTO): void {
    this.createClientDialog.hide();
    // console.log("createdUser ", createdUser);
    this.getCustomers();
    if (createdUser) {
      this.customerCBObj.value = createdUser.userId;
      this.selCustomer = createdUser;
      this.customerExists = true;
      this.invalidSSN = false;
      this.emitCustomerChange();
    }
  }

  onClientCancelled(error: string): void {
    this.createClientDialog.hide();
    if (error.length > 0) {
      this.toastComponent.show({
        title: 'Kan inte lägga till kunden',
        content: error
      });
    }
  }

  onKeyDown(args) {
    if (args.key === 'Enter' && !this.customerExists && !this.invalidSSN) {
      // check for flag and Enter key
      // your code here
      this.createClient();
    }
  }

  private getCustomers(): void {
    this.isLoadingCustomers = false;
    this.userService
      .getUsers(0, 100000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        page => {
          this.customers = page.content.map(user => ({
            ...user,
            fullName: user?.firstname + ' ' + user?.lastname,
            PNName: `${[user?.username.slice(0, 8), '-', user?.username.slice(8)].join('')} : ${
              user?.firstname
            } ${user?.lastname}`
          }));
          // console.log('this.customers ', this.customers);
          this.isLoadingCustomers = false;
        },
        () => (this.isLoadingCustomers = false)
      );
  }

  updateClient(): void {
    this.updateClientDialog.show();
  }

  onClientUpdated(): void {
    this.updateClientDialog.hide();
    this.getCustomers();
  }

  private getLocations(): void {
    this.isLoadingLocations = true;
    this.locationService
      .getActiveLocations()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        locations => {
          this.locations = locations;
          this.locations.unshift(this.nonLocation);
          this.isLoadingLocations = false;
        },
        () => (this.isLoadingLocations = false)
      );
  }

  private getSubjects(): void {
    this.isLoadingSubjects = true;
    this.subjectService
      .getActiveGroupedSubjects()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        subjects => {
          this.subjects = subjects;
          this.subjects = this.subjects.filter(sub => sub.groupHeader == false);
          this.subjects.forEach((sub: SubjectDTO, i) => {
            if (sub.group == '') {
              sub.group = 'Annat';
            }
          });
          this.isLoadingSubjects = false;
        },
        () => (this.isLoadingSubjects = false)
      );
  }

  onCCBFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    e.updateData(null);

    let max = 12;

    if (e.text === '') {
      e.preventDefaultAction = true;
      e.updateData(null);
      this.customerCBObj.hidePopup();

      this.selCustomer = null;

      if (!this.selSubject) {
        this.selSubject = undefined;
      }

      // this.selCustomerInfo = null;
      // this.selBookingInfo = [];

      // this.getBlocksBySubject(this.selSubject);
      this.invalidSSN = true;
      return;
    } else {
      if (e.text.includes('-')) {
        max = 13;
      }
      if (e.text.length < max) {
        e.updateData(null);
        this.invalidSSN = true;
        return;
      }

      if (max == 12) {
        e.text = [e.text.slice(0, 8), '-', e.text.slice(8)].join('');
      }

      let ssn = e.text.replace('-', '');
      ssn = ssn.slice(2, ssn.length);

      if (isValidSsn(ssn)) {
        this.invalidSSN = false;
        this.ssn = e.text;
        this.userService
          .getUserByUsername(e.text.replace('-', ''))
          .pipe(
            takeUntil(this.onDestroy$),
            map(user => {
              return {
                ...user,
                PNName: `${[user?.username.slice(0, 8), '-', user?.username.slice(8)].join('')} : ${
                  user?.firstname
                } ${user?.lastname}`
              };
            })
          )
          .subscribe(
            user => {
              // Populate DropDown list
              e.updateData(<any>[user]);

              const isKund = user.roles.find(role => role.role === 'USER');

              if (isKund == undefined) {
                this.customerExists = false;
              } else {
                this.customerExists = true;
              }
            },
            () => {
              this.customerExists = false;
            }
          );
      } else {
        this.invalidSSN = true;
        this.customerExists = false;
        this.ssn = '';
      }
    }
  };

  onCCBComplete(args): void {
    // console.log('onCCBComplete args.data', args);
    // console.log('this.selCustomer 1 ', this.selCustomer);

    if (!this.selCustomer) {
      const len = args.result.length - 1;
      // console.log('len ', len);
      this.selCustomer = args.result[len];
    }

    // console.log('this.selCustomer 2 ', this.selCustomer);
    if (this.selCustomer) {
      // console.log('INSIDE IF');
      this.customerCBObj.value = this.selCustomer.userId;
      this.emitCustomerChange();
    }
  }

  public clearSubjects(): void {
    this.selSubject = undefined;
    this.subjectDDLObject.value = null;
    this.subjectDDLObject.hidePopup();
    this.emitCustomerChange();
  }

  public onSubjectChange(args): void {
    this.selSubject = args.itemData;
    this.emitCustomerChange();

    // if (args.itemData == null || args.itemData.subjectId == 100) {
    //   this.selSubject = undefined;
    // } else {
    //   this.selSubject = args.itemData.subjectId;
    // }

    // if (!this.selLocation) {
    //   this.selLocation = undefined;
    // }
  }

  onCustomerCBChange(args): void {
    this.selCustomer = args.itemData;
    this.emitCustomerChange();

    // if (args.itemData) {
    //   this.selCustomer = args.itemData.userId;

    //   if (!this.selSubject) {
    //     this.selSubject = undefined;
    //   }

    //   this.getBlocksBySubject(this.selSubject);

    //   const customer = this.customers.find(customer => customer.userId === this.selCustomer);

    //   if (customer.phone != null && customer.phone != '') {
    //     const phone = phoneNumberUtil.parseAndKeepRawInput(customer.phone, 'SE');
    //     const formatedPhone = phoneNumberUtil.format(phone, PNF.NATIONAL);
    //     customer.phone = formatedPhone;
    //   }

    //   this.selBookingInfoFiller();
    //   this.selCustomerInfo = customer;
    // } else {
    //   this.selCustomer = null;

    //   if (!this.selSubject) {
    //     this.selSubject = undefined;
    //   }

    //   this.selCustomerInfo = null;
    //   this.selBookingInfo = [];

    //   this.getBlocksBySubject(this.selSubject);
    // }

    // const ele = document.getElementById('scheduleContainer');
    // if (this.selCustomer != null) {
    //   ele.classList.remove('schedule-container');
    //   ele.classList.add('schedule-container-list');
    // } else {
    //   ele.classList.add('schedule-container');
    //   ele.classList.remove('schedule-container-list');
    // }
  }

  onCCBFocus(args): void {
    (this.customerCBObj as any).keyConfigure.up = '';
    (this.customerCBObj as any).keyConfigure.down = '';

    // (this.customerCBObj as any).keyConfigure.enter = '';
    // if (!this.invalidSSN && !this.customerExists) {
    //   (this.customerCBObj as any).keyConfigure.enter = 'this.testing()';
    // }
  }

  onLocationChange(args): void {
    this.selLocation = args.itemData;
    this.emitCustomerChange();

    // if (!this.selSubject) {
    //   this.selSubject = undefined;
    // }

    // this.getBlocksBySubject(this.selSubject);
    // this.isPreviousShown = false;
  }

  newBooking() {
    this.action.emit('newBooking');
  }
}
