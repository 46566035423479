import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RoleDTO, RoleService, UserDTO, UserService } from 'booking-sdk';

import { isValidSwedishSSN } from 'src/app/shared/validators';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastComponent, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {
  @Input()
  ssn: string;

  @Output('client-created')
  clientCreated = new EventEmitter<UserDTO>();

  @Output()
  cancel = new EventEmitter<string>();

  user: UserDTO;
  existingUser: boolean = false;

  form: FormGroup;
  isLoading: boolean = false;
  isSaving: boolean = false;
  private onDestroy$ = new Subject();

  @ViewChild('Toast') toastComponent: ToastComponent;
  toastPosition: ToastPositionModel = { X: 'center', Y: 'bottom' };

  constructor(private formBuilder: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.minLength(12), isValidSwedishSSN]],
      phone: ['', [Validators.required, this.phoneNumberValidator('SE')]],
      email: ['', [Validators.email]],
      active: [true],
      roles: [[{ id: '15', role: 'USER' }]]
    });

    if (this.ssn) {
      this.form.get('username').patchValue(this.ssn);
    }
  }

  ngOnChanges(): void {
    this.existingUser = false;
    // console.log('this.ssn ', this.ssn);
    if (this.ssn) {
      this.userService.getUserByUsername(this.ssn.replace('-', '')).subscribe(user => {
        // console.log('user', user);
        this.existingUser = true;
        this.user = user;
        if (this.form) {
          this.form.get('firstname').patchValue(this.user?.firstname);
          this.form.get('lastname').patchValue(this.user?.lastname);
          this.form.get('username').patchValue(this.ssn);
          this.form.get('phone').patchValue(this.user?.phone);
          this.form.get('email').patchValue(this.user?.email);
        }
      });
    }

    if (this.form) {
      this.form.get('username').patchValue(this.ssn);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.addClient();
    } else {
      this.form.markAllAsTouched();
    }
  }

  onCancel(): void {
    this.form.reset();
    this.cancel.emit('');
  }

  phoneNumberValidator(regionCode: string = undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false;
      try {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {}

      return validNumber ? null : { wrongNumber: { value: control.value } };
    };
  }

  isIncorrectSSNLength(): boolean {
    const usernameCtrl = this.form.get('username');
    const requiredError = usernameCtrl.hasError('required');
    const minLengthError = usernameCtrl.hasError('minlength');
    const touched = usernameCtrl.touched;

    return (requiredError || minLengthError) && touched;
  }

  isInvalidSSN(): boolean {
    const usernameCtrl = this.form.get('username');
    const invalidSSNError = usernameCtrl.hasError('invalidSSN');
    const requiredError = usernameCtrl.hasError('required');
    const minLengthError = usernameCtrl.hasError('minlength');
    const touched = usernameCtrl.touched;

    return invalidSSNError && !(requiredError || minLengthError) && touched;
  }

  private addClient(): void {
    this.isSaving = true;
    const userDto: UserDTO = {
      ...this.form.value
    };
    userDto.username = userDto.username.replace('-', '');

    if (this.existingUser) {
      const roles: RoleDTO = {
        id: 15,
        role: 'USER',
        comment: 'Kund'
      };
      // console.log("kundRole", kundRole);
      // console.log("user.roles", user.roles[0]);
      this.user.roles.push(roles);
      // this.user.roles = [];
      // this.user.roles = roles;
      // console.log('this.user ', this.user);

      this.userService.updateUser(this.user.userId, this.user).subscribe(
        user => {
          // console.log("Updated user ", user);
          this.form.reset();
          this.isSaving = false;
          this.clientCreated.emit(user);
        },
        error => {
          this.isSaving = false;
          // console.log("error", error);
          // this.cancel.emit(error.error.message);
          this.toastComponent.content = error.error.message;
          this.toastComponent.show();
        }
      );
    } else {
      // console.log("userDto ", userDto);
      this.userService
        .addUser(userDto)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          user => {
            // console.log("user ", user);
            this.form.reset();
            this.isSaving = false;
            this.clientCreated.emit(user);
          },
          error => {
            this.isSaving = false;
            // this.cancel.emit(error.error.message);
            this.toastComponent.content = error.error.message;
            this.toastComponent.show();
          },
          () => {
            this.isSaving = false;
          }
        );
    }
  }
}
