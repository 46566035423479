<!-- <div class="root" *ngIf="true || isLoadingLocations || isLoadingSubjects" fxLayout="column">
  <ejs-progressbar
    fxFlexAlign="center"
    type="Circular"
    [maximum]="100"
    [minimum]="0"
    [value]="20"
    width="60px"
    height="60px"
    isIndeterminate="true"
    progressColor="#31a3ae"
  >
  </ejs-progressbar>
</div> -->

<form [formGroup]="form" *ngIf="booking">
  <div class="inputs" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50%" fxLayout="column">
        <app-user-info-block [userId]="booking.customer.userId"></app-user-info-block>

        <div fxLayout="column" fxLayoutGap="10px">
          <!-- *ngIf="!booking?.subjects[0]?.notDigital" -->
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="start center"
            class="topgap"
            (change)="onDigitalChange($event)"
          >
            <label class="checkbox-label" for="Digital">Möte via Teams eller telefon </label>
            <input
              #Digital
              type="checkbox"
              id="Digital"
              name="Digital"
              formControlName="Digital"
              class="e-field e-input"
            />
          </div>

          <!-- booking?.digital && booking?.telemeeting == false -->
          <div id="text" (change)="onDigitalTypeChange($event)">
            <div fxLayoutAlign="start center">
              <input
                *ngIf="isDigital"
                type="radio"
                id="Teams"
                name="meetingType"
                value="Telemeeting"
                [checked]="!isTelemeeting"
                style="margin-bottom: 15px; margin-right: 10px"
              />
              <label *ngIf="isDigital" for="Teams"> Möte via Teams</label>
            </div>

            <div fxLayoutAlign="start center">
              <input
                *ngIf="isDigital"
                type="radio"
                id="Telephone"
                name="meetingType"
                value="Telemeeting"
                [checked]="isTelemeeting"
                style="margin-bottom: 15px; margin-right: 10px"
              />
              <label *ngIf="isDigital" for="Telephone"> Möte via telefon</label>
            </div>
          </div>

          <!-- <ejs-radiobutton
            [label]="digitalLocation?.label"  
            [value]="digitalLocation?.locationId"
            [checked]="booking?.digital && booking?.telemeeting == false"
            (change)="onLocationChange($event)"
            
          >
          </ejs-radiobutton>
          <ejs-radiobutton
            [label]="telephoneLocation?.label"
            [value]="telephoneLocation?.locationId"
            [checked]="booking?.digital && booking?.telemeeting == true"
            (change)="onLocationChange($event)"
            
          >
          </ejs-radiobutton> -->

          <!-- <ejs-radiobutton
            [(ngModel)]="Teams" 
            value="Teams" 
            label="Möte via Teams"
          >
          </ejs-radiobutton>
          <ejs-radiobutton
            [(ngModel)]="form" 
            value="Telemeeting" 
            label="Möte via telefon"
          >
          </ejs-radiobutton> -->

          <!-- Möte via Teams -->
          <!-- Möte via telefon -->

          <!-- ng-disabled="booking?.subjects[0].notDigital" -->

          <!-- <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="reduceGap">
            <label class="checkbox-label" for="SendConfirmation">Bekräftelse via SMS</label>
            <input
              type="checkbox"
              id="SendConfirmation"
              name="SendConfirmation"
              formControlName="SendConfirmation"
              class="e-field e-input"
            />
          </div> -->

          <div fxLayout="row" fxLayoutGap="5px" class="reduceGap">
            <div>Bokad genom:</div>
            <div>{{ booking.createdBy.firstname }} {{ booking.createdBy.lastname }}</div>
          </div>

          <div fxLayout="row" fxLayoutGap="5px" class="reduceGap">
            <div>Bokad resurs:</div>
            <div *ngIf="booking?.resource && booking?.resource?.premium" class="premium">
              <div>
                {{ booking.resource?.name }} - {{ booking.resource?.resourceType?.name }} (P)
              </div>
            </div>
            <div *ngIf="booking?.resource && !booking?.resource?.premium">
              <div>{{ booking.resource?.name }} - {{ booking.resource?.resourceType?.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex="50%" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column">
          <app-subject-selector
            [subjects]="subjects"
            formControlName="Subject"
            (subjectChange)="onSubjectChange($event)"
          ></app-subject-selector>
        </div>

        <!-- <div fxLayout="column" *ngIf="form.value.Subject?.freeText">
          <label for="FreeText">Kort beskrivning om ärende</label>
          <ejs-richtexteditor
            id="FreeText"
            name="FreeText"
            formControlName="FreeText"
            [enableResize]="true"
            [toolbarSettings]="editorToolbarSettings"
            [saveInterval]="500"
          ></ejs-richtexteditor>
        </div> -->

        <div fxLayout="column">
          <app-location-selector
            [locations]="locations"
            [disabled]="true"
            [readonly]="true"
            formControlName="Location"
          ></app-location-selector>
        </div>

        <div fxLayout="column">
          <app-advisor-selector
            [advisors]="advisors"
            formControlName="Advisor"
          ></app-advisor-selector>
        </div>
      </div>
    </div>

    <!-- (focus)="onStartFocused()" -->
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50%" fxLayout="column">
        <label for="StartTime">Från</label>
        <ejs-datetimepicker
          #StartTime
          id="StartTime"
          name="StartTime"
          [class.e-error]="form.get('StartTime').invalid && form.get('StartTime').touched"
          class="e-field e-input"
          formControlName="StartTime"
          format="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          [allowEdit]="false"
          [firstDayOfWeek]="firstDayOfWeek"
          (change)="onStartTimeUpdated($event)"
        ></ejs-datetimepicker>
        <div
          fxFlexOffset="5"
          *ngIf="form.get('StartTime').invalid && form.get('StartTime').touched"
          class="error-message"
        >
          Endast hel eller halvtimme dvs. 00 eller 30 minuter
        </div>
      </div>

      <div fxFlex="50%" fxLayout="column">
        <label for="EndTime">Till</label>
        <ejs-datetimepicker
          id="EndTime"
          name="EndTime"
          [class.e-error]="form.get('EndTime').invalid && form.get('EndTime').touched"
          class="e-field e-input"
          formControlName="EndTime"
          format="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          [allowEdit]="false"
          [firstDayOfWeek]="firstDayOfWeek"
          (change)="onEndTimeUpdated($event)"
        ></ejs-datetimepicker>
        <div
          fxFlexOffset="5"
          *ngIf="form.get('EndTime').invalid && form.get('EndTime').touched"
          class="error-message"
        >
          Endast hel eller halvtimme dvs. 00 eller 30 minuter
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50%" fxLayout="column">
        <label for="Description">Information till kund (i bekräftelseruta samt SMS)</label>
        <!-- [enabled]="false" -->
        <ejs-richtexteditor
          #descRTE
          id="Description"
          class="Description"
          name="Description"
          [readonly]="true"
          formControlName="Description"
          [enableResize]="true"
          [toolbarSettings]="editorToolbarSettings"
        ></ejs-richtexteditor>
      </div>

      <div fxFlex="50%" fxLayout="column">
        <!-- <label *ngIf="!selectedSubject.freeText" for="ClientInfo">Information till rådgivaren (ses av kund)</label>
        <label *ngIf="selectedSubject.freeText" for="ClientInfo">Kort beskrivning om ärende</label> -->
        <label for="ClientInfo">Information till rådgivaren (ses av kund)</label>

        <ejs-richtexteditor
          #infoRTE
          id="ClientInfo"
          name="ClientInfo"
          formControlName="ClientInfo"
          [enableResize]="true"
          [toolbarSettings]="editorToolbarSettings"
          [saveInterval]="500"
        ></ejs-richtexteditor>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="100%" fxLayout="column">
        <label for="AdvisorInfo">Intern information till rådgivaren (ej synlig för kund)</label>
        <ejs-richtexteditor
          #advisorInfoRTE
          id="AdvisorInfo"
          name="AdvisorInfo"
          formControlName="AdvisorInfo"
          [enableResize]="true"
          [toolbarSettings]="editorToolbarSettings"
          [saveInterval]="500"
        ></ejs-richtexteditor>
      </div>
    </div>
  </div>
</form>
