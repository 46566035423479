import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorComponent,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import {
  AdvisorDTO,
  BlockDTO,
  BookingDTO,
  LocationDTO,
  RoleDTO,
  RoleService,
  SubjectDTO,
  UserDTO,
  UserService,
  BookingService
} from 'booking-sdk';
import dayjs from 'dayjs';

@Component({
  selector: 'app-show-sms',
  templateUrl: './show-sms.component.html',
  styleUrls: ['./show-sms.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ShowSmsComponent implements OnInit {
  @Input()
  smsBody: string = '';
  @Output()
  smsBodyChange = new EventEmitter<string>();

  cssClass: String = 'smsClass';

  @ViewChild('showSMS') showSMS: RichTextEditorComponent;

  editorToolbarSettings: ToolbarModule = { items: [] };

  constructor() {}

  ngOnInit(): void {}

  updateSmsBody(arg) {
    this.smsBodyChange.emit(this.showSMS.getText());
  }
  // ngOnChanges(): void {
  //   this.smsBodyChange.emit(this.smsBody);
  //   console.log(this.smsBody);
  // }

  // 197411017978

  onCancel(): void {
    // this.form.reset();
    // this.cancel.emit();
  }

  // 197411017978

  // private constructSMSPreview(): void {
  //   const booking = this.convertToBookingDto();
  //   if (booking.bookingId) {
  //     this.bookingService.getSMSPreviewAsHtml(booking).subscribe(message => {
  //       this.smsBody = message.body;
  //     });
  //   }
  // }

  // 197411017978
}
