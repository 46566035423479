import { jsDocComment } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  AdvisorDTO,
  AdvisorService,
  BookingDTO,
  LocationDTO,
  LocationService,
  SubjectDTO,
  SubjectService
} from 'booking-sdk';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { DateTimePicker } from '@syncfusion/ej2-angular-calendars';
import { ButtonComponent, ChangeArgs } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-booking-editor',
  templateUrl: './booking-editor.component.html',
  styleUrls: ['./booking-editor.component.scss']
})
export class BookingEditorComponent implements OnInit, OnChanges {
  @Input()
  booking: BookingDTO;

  @Output()
  bookingChange: EventEmitter<BookingDTO> = new EventEmitter<BookingDTO>();

  @Output()
  telemeetingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  timeError: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('infoRTE') clientinfoRTE: RichTextEditorComponent;
  @ViewChild('advisorInfoRTE') advisorinfoRTE: RichTextEditorComponent;
  @ViewChild('StartTime') startTimeDTP: DateTimePicker;

  @Input()
  editorDialogObj: any;

  badTime: boolean = false;

  locations: LocationDTO[];
  subjects: SubjectDTO[];
  advisors: AdvisorDTO[];
  editorToolbarSettings: ToolbarModule = { enable: false, items: [] };
  form: FormGroup;
  firstDayOfWeek: 1;
  private onDestroy$ = new Subject();

  selectedSubject: SubjectDTO;

  isDisabled: Boolean = false;

  isDigital: Boolean = false;
  isTelemeeting: boolean = false;

  digitalLocation: LocationDTO = {
    locationId: -1,
    label: 'Teams',
    orderBy: 0,
    active: false
  };

  telephoneLocation: LocationDTO = {
    locationId: -2,
    label: 'Telefon',
    orderBy: 0,
    active: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private advisorService: AdvisorService,
    private subjectService: SubjectService
  ) {}

  ngOnInit(): void {
    // console.log('ngOnInit - this.booking ', this.booking);
    this.form = this.formBuilder.group({
      // Location:[{ value: this.booking?.location, disabled: true }],
      // Subject: [{ value: this.booking?.subjects[0], disabled: true }],
      // Advisor: [{ value: this.booking?.advisor, disabled: true }],
      Location: [this.booking?.location],
      Subject: [this.booking?.subjects[0]],
      Advisor: [this.booking?.advisor],
      Digital: [{ value: this.booking?.digital, disabled: this.isDisabled }],
      Telemeeting: [{ value: this.booking?.telemeeting, disabled: this.isDisabled }],
      SendConfirmation: [this.booking?.sendConfirmation],
      StartTime: [this.booking?.fromDate, [Validators.required, this.validateTime()]],
      EndTime: [this.booking?.toDate, [Validators.required, this.validateTime()]],
      Description: [this.booking?.description],
      ClientInfo: [this.booking?.clientinfo],
      AdvisorInfo: [this.booking?.advisorinfo]
      // ,
      // FreeText: [this.booking?.subjectFreeText]
    });

    if (this.booking?.subjects) {
      this.selectedSubject = this.booking.subjects[0];
    }
    // console.log("selectedSubject", this.selectedSubject);

    if (this.booking?.digital) {
      this.isDigital = true;
    } else {
      this.isDigital = false;
    }

    if (this.booking?.telemeeting) {
      this.isTelemeeting = true;
    } else {
      this.isTelemeeting = false;
    }

    // console.log("this.isTelemeeting ", this.isTelemeeting);

    this.form.valueChanges.subscribe(val => {
      // console.log("this.form.valueChanges.subscribe", val);
      this.form.value.AdvisorInfo = this.advisorinfoRTE.getText();
      this.form.value.ClientInfo = this.clientinfoRTE.getText();
      // console.log("this.isTelemeeting ", this.isTelemeeting);
      // this.form.value.Telemeeting = this.isTelemeeting;
      // console.log(this.form.value);
      // console.log("this.form.value.Telemeeting ", this.form.value.Telemeeting);
      this.bookingChange.emit(this.form.value);
    });
  }

  onDigitalChange(args: any): void {
    // console.log('((((((((( onDigitalChange() ))))))))');
    // console.log('args.srcElement.id', args.srcElement.id);

    // console.log('this.form.value.Digital', this.form.value.Digital);

    if (this.form.value.Digital) {
      this.isDigital = true;
    } else {
      this.isDigital = false;
    }
  }

  onDigitalTypeChange(args: any): void {
    // console.log('((((((((( onDigitalTypeChange() ))))))))');
    // console.log('args.srcElement.id', args.srcElement.id);

    if (args.srcElement.id == 'Telephone') {
      this.isTelemeeting = true;
    } else {
      this.isTelemeeting = false;
    }

    this.telemeetingChange.emit(this.isTelemeeting);

    // console.log('this.isTelemeeting ', this.isTelemeeting);
  }

  // 197411017978

  validateTime(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validTime = false;
      // console.log(control.value);
      try {
        // console.log("dayjs minutes ", dayjs(control.value).minute());
        if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
          validTime = true;
        }
        // const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
        // validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {}
      // console.log("validTime", validTime);
      return validTime ? null : { wrongTime: { value: control.value } };
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges', changes);
    // console.log('booking', this.booking);
    // console.log("this.form.value", this.form.value);

    if (this.booking?.subjects[0].notDigital) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }

    if (this.booking?.digital) {
      this.isDigital = true;
    } else {
      this.isDigital = false;
    }

    // const digitalCB = document.getElementById('Digital');
    // console.log("digitalCB", digitalCB);
    // if (digitalCB) {
    //   if (this.booking?.subjects[0].notDigital) {
    //     console.log("add");
    //     digitalCB.classList.add('disable-digital');
    //   } else {
    //     console.log("remove");
    //     digitalCB.classList.remove('disable-digital');
    //   }
    // }

    if (this.booking?.subjects) {
      this.selectedSubject = this.booking.subjects[0];
    }
    // console.log("selectedSubject", this.selectedSubject);

    if (
      changes.booking &&
      changes.booking.currentValue &&
      JSON.stringify(changes.booking.previousValue) !== JSON.stringify(changes.booking.currentValue)
    ) {
      this.getLocations()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(locations => {
          this.locations = locations;
          this.getSubjects()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(subjects => {
              this.subjects = subjects;

              this.getAdvisors()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(advisors => {
                  this.advisors = advisors;
                  // console.log("/////////////////// BOOKING-EDITOR ngOnChanges ///////////////////");
                  // console.log("this.booking ", this.booking);
                  // console.log("this.subjects ", this.subjects);
                  // console.log("//////////////////////////////////////////////////////////////////");
                  this.form.patchValue({
                    Location: this.booking.location,
                    Subject: this.booking.subjects[0],
                    Advisor: this.booking.advisor,
                    Digital: this.booking.digital,
                    Telemeeting: this.booking.telemeeting,
                    SendConfirmation: this.booking.sendConfirmation,
                    StartTime: this.booking.fromDate,
                    EndTime: this.booking.toDate,
                    Description: this.booking.description,
                    ClientInfo: this.booking.clientinfo,
                    AdvisorInfo: this.booking.advisorinfo
                    // ,
                    // FreeText: this.booking.subjectFreeText
                  });
                });
            });
        });
    }
  }

  private getLocations(): Observable<LocationDTO[]> {
    return this.locationService.getActiveLocations().pipe(
      map(locations => {
        // Only keep location from booking to use as readonly field
        locations = locations.filter(l => l.locationId === this.booking.location.locationId);
        // Add location from booking if it doesn't already exists in list
        if (!locations.find(l => l.locationId === this.booking.location.locationId)) {
          locations.push(this.booking.location);
        }
        return locations;
      })
    );
  }

  private getSubjects(): Observable<SubjectDTO[]> {
    return this.subjectService
      .getActiveSubjects(this.booking ? this.booking.advisor.userId : undefined)
      .pipe(
        map(subjects => {
          // Add location from booking if it doesn't already exists in list
          if (!subjects.find(s => s.subjectId === this.booking.subjects[0].subjectId)) {
            subjects.push(this.booking.subjects[0]);
          }
          subjects.sort((a, b) => (a.orderBy < b.orderBy ? -1 : a.orderBy > b.orderBy ? 1 : 0));
          return subjects;
        })
      );
  }

  private getAdvisors(): Observable<AdvisorDTO[]> {
    return this.advisorService.getAdvisors(undefined, undefined, undefined, 0, 1000).pipe(
      map(advisorPage => {
        // Only keep advisor from booking to use as readonly field
        let advisors = advisorPage.content.filter(a => a.userId === this.booking.advisor.userId);
        // Add advisor from booking if it doesn't already exists in list
        // if (!advisors.find(a => a.userId === this.booking.advisor.userId)) {
        //   advisors.push(this.booking.advisor);
        // }
        return advisors;
      })
    );
  }

  // public getBooking() : BookingDTO {
  //   return this.value;
  // }

  // public isValid() : boolean {
  //   return this.form.valid;
  // }
  onSubjectChange(subject) {
    // console.log("/////////////////// BOOKING-EDITOR onSubjectChange ///////////////////");
    // console.log("subject ", subject);
    // console.log("this.subjects ", this.subjects);
    // console.log("//////////////////////////////////////////////////////////////////////");
    if (subject) {
      this.form.patchValue({ Description: subject.description });

      this.selectedSubject = subject;

      // console.log("selectedSubject", this.selectedSubject);
    }
  }

  onStartFocused(): void {
    // console.log('onStartFocused');
    // this.startTimeDTP.element.addEventListener('keyup', () => {
    //     console.log("this.form.controls.StartTime", this.form.controls.StartTime.value);
    //     // console.log("Focusinnnnnnnnnng");
    //     // this.startTimeDTP.focusOut();
    //     // this.startTimeDTP.focusIn();
    // });
    // this.startTimeDTP.element.addEventListener('keyup', this.onStartTimeUpdated.bind(this));
    // this.startTimeDTP.element.addEventListener('keyup', () => {
    //     // this.onStartTimeUpdated(this.form.value.StartTime);
    //     console.log("this", this);
    //     console.log("this.form", this.form);
    //     console.log("this.form.controls", this.form.controls);
    //     console.log("this.form.controls.StartTime", this.form.controls.StartTime);
    //     this.onStartTimeUpdated(this.form.controls.StartTime);
    // });
  }

  onStartTimeUpdated(control) {
    // console.log('### control 1 ###', control);
    // console.log("/////////////////// BOOKING-EDITOR onStartTimeUpdated ///////////////////");
    // console.log("subject ", control);
    // console.log("/////////////////////////////////////////////////////////////////////////");

    if (control.event == null) {
      return;
    }

    let buttons = this.editorDialogObj.buttons;

    // console.log("buttons 1", buttons);

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log('VALID 1');

      this.timeError.emit(false);

      let startTime = dayjs(this.form.value.StartTime);
      let endTime = dayjs(this.form.value.EndTime);
      const bookingLength = endTime.diff(startTime, 'minute');

      this.form.patchValue({ StartTime: dayjs(control.value).toDate() });

      // console.log('startTime 1 ', dayjs(startTime).toDate());
      // console.log('endTime 1 ', dayjs(endTime).toDate());
      // console.log('bookingLength 1 ', bookingLength);

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }

      this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    } else {
      // console.log('INVALID 1');

      this.timeError.emit(true);

      let startTime = dayjs(this.form.value.StartTime);
      let endTime = dayjs(this.form.value.EndTime);
      const bookingLength = endTime.diff(startTime, 'minute');

      this.form.patchValue({ StartTime: dayjs(control.value).toDate() });

      // console.log("startTime 1 ", dayjs(startTime).toDate());
      // console.log("endTime 1 ", dayjs(endTime).toDate());
      // console.log("bookingLength 1 ", bookingLength);

      if (buttons) {
        // console.log("in buttons")
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      // console.log("buttons 2", buttons);
      this.form.markAllAsTouched();
      this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }

  onEndTimeUpdated(control) {
    // console.log("/////////////////// BOOKING-EDITOR onEndTimeUpdated ///////////////////");
    // console.log("subject ", control);
    // console.log("///////////////////////////////////////////////////////////////////////");
    // console.log("control 2", control);
    // console.log("this.form ", this.form);
    // console.log("this.form.status ", this.form.status);

    // if (this.form.valid) {

    let buttons = this.editorDialogObj.buttons;

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log("VALID 2");

      this.timeError.emit(false);

      this.form.patchValue({ EndTime: dayjs(control.value).toDate() });
      // let startTime = dayjs(this.form.value.StartTime);
      // let endTime = dayjs(this.form.value.EndTime);
      // const bookingLength = endTime.diff(startTime, 'minute');

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }
    } else {
      // console.log("INVALID 2");

      this.timeError.emit(true);

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      this.form.markAllAsTouched();
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }

  // onStartTimeUpdated(control) {
  //   // Update EndTime based on StartTime
  //   let startTime = dayjs(this.form.value.StartTime);
  //   let endTime = dayjs(this.form.value.EndTime);
  //   const bookingLength = endTime.diff(startTime, 'minute');

  //   this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
  // }
}
