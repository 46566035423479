<label for="ScheduleType">Händelse</label>
<ejs-dropdownlist
  id="ScheduleType"
  name="ScheduleType"
  class="e-dropdownlist e-field"
  [dataSource]="currentScheduleTypes"
  [fields]="scheduleTypeFields"
  [(value)]="currentScheduleTypeId"
  (change)="onScheduleTypeChange($event)"
  [enabled]="!disabled"
></ejs-dropdownlist>
