import { jsDocComment } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  LocationDTO,
  LocationService,
  ResourceDTO,
  ResourceScheduleDTO,
  ResourceScheduleService,
  ResourceService
} from 'booking-sdk';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { PopupOpenEventArgs } from '@syncfusion/ej2-angular-schedule';

@Component({
  selector: 'app-resource-schedule-editor',
  templateUrl: './resource-schedule-editor.component.html',
  styleUrls: ['./resource-schedule-editor.component.scss']
})
export class ResourceScheduleEditorComponent implements OnInit, OnChanges {
  @Input()
  resourceSchedule: ResourceScheduleDTO;

  @Input()
  selectedResource: ResourceDTO;

  @Output()
  resourceScheduleChange: EventEmitter<ResourceScheduleDTO> = new EventEmitter<ResourceScheduleDTO>();

  locations: LocationDTO[];
  resources: ResourceDTO[];
  resource: any;
  resourceFields: Object = { text: 'fullName', value: 'resourceId' };

  editorToolbarSettings: ToolbarModule = { items: [] };
  form: FormGroup;
  firstDayOfWeek: 1;
  private onDestroy$ = new Subject();
  isLoadingScheduleTypes: boolean;
  isLoadingLocations: boolean;
  isLoadingResources: boolean;

  @Input()
  editorDialogObj: any;

  status = [
    {
      id: 'AVAILABLE',
      name: 'TILLGÄNGLIG'
    },
    {
      id: 'UNAVAILABLE',
      name: 'STÄNGD'
    }
  ];
  statusFields: Object = { text: 'name', value: 'id' };

  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private resourceService: ResourceService,
    private resourceScheduleService: ResourceScheduleService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      resource: [this.resourceSchedule?.resource],
      location: [this.resourceSchedule?.location],
      Location: [this.resourceSchedule?.location?.label],
      StartTime: [this.resourceSchedule?.fromDate, [Validators.required, this.validateTime()]],
      EndTime: [this.resourceSchedule?.toDate, [Validators.required, this.validateTime()]],
      comment: [this.resourceSchedule?.comment],
      status: [this.resourceSchedule?.status, [Validators.required]]
    });

    // console.log('this.selectedResource ', this.selectedResource);
    this.form.valueChanges.subscribe(val => {
      // console.log("this.form.value", this.form.value);
      this.resourceScheduleChange.emit(this.form.value);
    });

    this.getResources();
    this.getLocations();
  }

  validateTime(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validTime = false;
      // console.log(control.value);
      try {
        // console.log("dayjs minutes ", dayjs(control.value).minute());
        if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
          validTime = true;
        }
        // const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
        // validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {}
      // console.log("validTime", validTime);
      return validTime ? null : { wrongTime: { value: control.value } };
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.form &&
      changes.resourceSchedule &&
      changes.resourceSchedule.currentValue &&
      JSON.stringify(changes.resourceSchedule.previousValue) !==
        JSON.stringify(changes.resourceSchedule.currentValue)
    ) {
      // console.log("this.resourceSchedule ", this.resourceSchedule);
      // this.resource = {
      //   ...this.resourceSchedule?.resource,
      //   fullName: this.resourceSchedule?.resource?.name + ' - ' + this.resourceSchedule?.resource?.resourceType?.name
      // };
      // console.log("this.resourceSchedule?.resource ", this.resourceSchedule?.resource);
      // console.log("this.resource ", this.resource);
      this.form.patchValue({
        resource: this.resource,
        location: this.resourceSchedule.location,
        Location: this.resourceSchedule.location.label,
        StartTime: this.resourceSchedule.fromDate,
        EndTime: this.resourceSchedule.toDate,
        comment: this.resourceSchedule.comment,
        status: this.resourceSchedule.status
      });
    }
  }

  private getLocations(): void {
    this.isLoadingLocations = true;
    this.locationService
      .getActiveLocations()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        locations => {
          this.locations = locations;
          // this.selectedLocation = this.locations[0];
          this.isLoadingLocations = false;
        },
        () => (this.isLoadingLocations = false)
      );
  }

  private getResources(): void {
    this.isLoadingResources = true;

    this.resourceService
      .getActiveResources()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        resources => {
          this.resources = resources.map(resource => ({
            ...resource,
            fullName: resource?.name + ' - ' + resource?.resourceType?.name
          }));
          // console.log('this.resources ', this.resources);
          // this.selectedResource = this.resources[0];
          this.isLoadingResources = false;
        },
        () => (this.isLoadingResources = false)
      );

    // this.resourceService
    //   .getResources(0, 1000, undefined, undefined)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(
    //     page => {
    //       this.resources = page.content.map(resource => ({
    //         ...resource,
    //         fullName: resource?.name + ' - ' + resource?.resourceType?.name
    //       }));
    //       // console.log('this.resources ', this.resources);
    //       // this.selectedResource = this.resources[0];
    //       this.isLoadingResources = false;
    //     },
    //     () => (this.isLoadingResources = false)
    //   );
  }

  onStartTimeUpdated(control) {
    // console.log("control 1", control);
    // console.log("this.form ", this.form);
    // console.log("this.form.status ", this.form.status);

    // if (this.form.valid) {

    let buttons = this.editorDialogObj.buttons;

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log("VALID 1");
      // Update EndTime based on StartTime

      let startTime = dayjs(this.form.value.StartTime);
      let endTime = dayjs(this.form.value.EndTime);
      const bookingLength = endTime.diff(startTime, 'minute');

      this.form.patchValue({ StartTime: dayjs(control.value).toDate() });

      // console.log("startTime 1 ", dayjs(startTime).toDate());
      // console.log("endTime 1 ", dayjs(endTime).toDate());
      // console.log("bookingLength 1 ", bookingLength);

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }

      this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    } else {
      // console.log("INVALID 1");

      // console.log("buttons", buttons);
      if (buttons) {
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      this.form.markAllAsTouched();
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }

  onEndTimeUpdated(control) {
    // console.log("control 2", control);
    // console.log("this.form ", this.form);
    // console.log("this.form.status ", this.form.status);

    // if (this.form.valid) {

    let buttons = this.editorDialogObj.buttons;

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log("VALID 2");

      this.form.patchValue({ EndTime: dayjs(control.value).toDate() });
      // let startTime = dayjs(this.form.value.StartTime);
      // let endTime = dayjs(this.form.value.EndTime);
      // const bookingLength = endTime.diff(startTime, 'minute');

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }

      // this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    } else {
      // console.log("INVALID 2");

      // console.log("buttons", buttons);
      if (buttons) {
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      this.form.markAllAsTouched();
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }
}
