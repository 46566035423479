import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticatedGuard } from '../core/guards';
import { ProtectedRouteComponent } from './protected-route';

import { DeviceDetectorService } from 'ngx-device-detector';

const routesMobile: Routes = [
  {
    path: '',
    component: ProtectedRouteComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'my-bookings-mobile',
        loadChildren: () =>
          import('./my-bookings-mobile/my-bookings-mobile.module').then(
            m => m.MyBookingsMobileModule
          )
      },
      {
        path: '',
        redirectTo: '/my-bookings-mobile',
        pathMatch: 'full'
      }
    ]
  }
];

const routes: Routes = [
  {
    path: '',
    component: ProtectedRouteComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'subjects',
        loadChildren: () => import('./subjects/subjects.module').then(m => m.SubjectsModule)
      },
      // {
      //   path: 'subject-groups',
      //   loadChildren: () =>
      //     import('./subject-groups/subject-groups.module').then(m => m.SubjectGroupsModule)
      // },
      {
        path: 'locations',
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule)
      },
      {
        path: 'tags',
        loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule)
      },
      {
        path: 'resource-types',
        loadChildren: () =>
          import('./resource-types/resource-types.module').then(m => m.ResourceTypesModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'resource-schedules',
        loadChildren: () =>
          import('./resource-schedules/resource-schedules.module').then(
            m => m.ResourceSchedulesModule
          )
      },
      {
        path: 'resource-occupancy',
        loadChildren: () =>
          import('./resource-occupancy/resource-occupancy.module').then(
            m => m.ResourceOccupancyModule
          )
      },
      {
        path: 'schedules',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule)
      },
      {
        path: 'schedule-overviews',
        loadChildren: () =>
          import('./schedule-overviews/schedule-overviews.module').then(
            m => m.ScheduleOverviewsModule
          )
      },
      {
        path: 'booking-overviews',
        loadChildren: () =>
          import('./booking-overviews/booking-overviews.module').then(m => m.BookingOverviewsModule)
      },
      {
        path: 'schedule-templates',
        loadChildren: () =>
          import('./schedule-templates/schedule-templates.module').then(
            m => m.ScheduleTemplatesModule
          )
      },
      {
        path: 'my-schedule',
        loadChildren: () => import('./my-schedule/my-schedule.module').then(m => m.MyScheduleModule)
      },
      {
        path: 'my-bookings',
        loadChildren: () => import('./my-bookings/my-bookings.module').then(m => m.MyBookingsModule)
      },
      {
        path: 'my-bookings-mobile',
        loadChildren: () =>
          import('./my-bookings-mobile/my-bookings-mobile.module').then(
            m => m.MyBookingsMobileModule
          )
      },
      {
        path: 'new-booking',
        loadChildren: () => import('./new-booking/new-booking.module').then(m => m.NewBookingModule)
      },
      {
        path: 'clientcenter',
        loadChildren: () =>
          import('./clientcenter/clientcenter.module').then(m => m.ClientcenterModule)
      },

      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'helpitems',
        loadChildren: () => import('./helpitems/helpitems.module').then(m => m.HelpitemsModule)
      },
      {
        path: 'helpitem-files',
        loadChildren: () =>
          import('./helpitem-files/helpitem-files.module').then(m => m.HelpitemFilesModule)
      },
      {
        path: 'anonymize-user',
        loadChildren: () =>
          import('./anonymize-user/anonymize-user.module').then(m => m.AnonymizeUserModule)
      },
      {
        path: 'transfer-booking',
        loadChildren: () =>
          import('./transfer-booking/transfer-booking.module').then(m => m.TransferBookingModule)
      },
      {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule)
      },
      {
        path: 'sms-queue',
        loadChildren: () => import('./sms-queue/sms-queue.module').then(m => m.SmsQueueModule)
      },
      {
        path: 'booking-log',
        loadChildren: () => import('./booking-log/booking-log.module').then(m => m.BookingLogModule)
      },
      {
        path: 'sms-log',
        loadChildren: () => import('./sms-log/sms-log.module').then(m => m.SmsLogModule)
      },
      {
        path: 'demo-generate',
        loadChildren: () =>
          import('./demo-generate/demo-generate.module').then(m => m.DemoGenerateModule)
      },
      {
        path: '',
        redirectTo: '/new-booking',
        pathMatch: 'full'
      }

      // ,
      // {
      //   path: '',
      //   redirectTo: '/my-bookings-mobile',
      //   pathMatch: 'full'
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtectedRoutingModule {
  isMobile: boolean = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
    // console.log("this.isMobile ", this.isMobile);

    // if(this.isMobile) {
    //   RouterModule.forChild(routesMobile);
    //   console.log("RouterModule mobile", RouterModule.forChild(routesMobile));
    // } else {
    //   RouterModule.forChild(routes);
    //   console.log("RouterModule ", RouterModule.forChild(routes));
    // }

    // console.log("RouterModule ", RouterModule.forChild);
  }
}
