import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-protected-route',
  templateUrl: './protected-route.component.html',
  styleUrls: ['./protected-route.component.scss']
})
export class ProtectedRouteComponent {
  @Input()
  content: string;
  deviceInfo = null;
  isMobileDevice: boolean = false;
  showHelp: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
    // console.log("this.route.children", this.router.);
    this.checkDevice();
  }

  // ngOnInit() {
  //   console.log("this.route", this.route);
  //   this.route.queryParams.subscribe(params => {
  //     // this.name = params['name'];
  //     const name = params['name'];
  //     console.log('name', name);
  //     console.log('params', params);
  //   });
  // }

  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log("isMobile", isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log("isTablet", isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log("isDesktopDevice", isDesktopDevice); // returns if the app is running on a Desktop browser.

    if (isMobile) {
      this.isMobileDevice = isMobile;
      this.router.navigateByUrl('my-bookings-mobile');
      // console.log('this.router.url', this.router.url);
    }
  }

  contentChanged(content: string): void {
    this.content = content;
  }

  toggledDocking(docked: boolean): void {
    const sidebarElement = document.getElementById('sidebarComp');

    if (docked) {
      sidebarElement.classList.remove('content-undocked');
      sidebarElement.classList.add('content-docked');
    } else {
      sidebarElement.classList.remove('content-docked');
      sidebarElement.classList.add('content-undocked');
    }
  }

  showHelpChanged(showHelp: boolean): void {
    this.showHelp = showHelp;
  }
}
