import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubjectDTO } from 'booking-sdk';

@Component({
  selector: 'app-subject-selector',
  templateUrl: './subject-selector.component.html',
  styleUrls: ['./subject-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SubjectSelectorComponent
    }
  ]
})
export class SubjectSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  subjects: SubjectDTO[];

  @Input()
  subject: SubjectDTO;

  @Output()
  subjectChange: EventEmitter<SubjectDTO> = new EventEmitter<SubjectDTO>();

  onChange = subject => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  subjectFields: Object = { text: 'label', value: 'subjectId' };
  currentSubjectId: number;
  currentSubjects: SubjectDTO[];

  constructor() {}

  writeValue(subject: SubjectDTO) {
    this.subject = subject;

    if (this.subjects && this.subject) {
      this.updateList();
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    // console.log("******************* SUBJECT-SELECTOR ngOnChanges *******************");
    // console.log("changes ", changes);
    // console.log("this.subject ", this.subject);
    // console.log("this.subjects ", this.subjects);
    // console.log("********************************************************************");
    if (changes.subjects && changes.subjects.currentValue && this.subject) {
      this.updateList();
    }
  }

  private updateList() {
    // Copy original list
    this.currentSubjects = JSON.parse(JSON.stringify(this.subjects));

    if (this.subject) {
      this.currentSubjectId = this.subject.subjectId;

      // Add subject from booking to list, if it doesn't already exists
      if (!this.currentSubjects.find(s => s.subjectId === this.subject.subjectId)) {
        this.currentSubjects.push(this.subject);
      }
    }
  }

  onSubjectChange(args) {
    // this.valueChange.emit(args.itemData);
    // console.log("******************* SUBJECT-SELECTOR onSubjectChange *******************");
    // console.log("args.itemData ", args.itemData);
    // console.log("this.subject ", this.subject);
    // console.log("this.subjects ", this.subjects);
    // console.log("************************************************************************");
    this.markAsTouched();
    this.subject = args.itemData;
    this.onChange(this.subject);
    this.subjectChange.emit(this.subject);
  }
}
