import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { RoleDTO, RoleService, UserDTO, UserService } from 'booking-sdk';

import { isValidSwedishSSN } from 'src/app/shared/validators';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastComponent, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent implements OnInit {
  @Input()
  user: UserDTO;

  @Output('client-updated')
  clientUpdated = new EventEmitter<UserDTO>();

  @Output()
  cancel = new EventEmitter<void>();

  form: FormGroup;
  isLoading: boolean = false;
  isSaving: boolean = false;
  private onDestroy$ = new Subject();

  @ViewChild('Toast') toastComponent: ToastComponent;
  toastPosition: ToastPositionModel = { X: 'center', Y: 'bottom' };

  constructor(private formBuilder: FormBuilder, private userService: UserService) {}

  // 197411017978

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.minLength(12), isValidSwedishSSN]],
      phone: ['', [Validators.required, this.phoneNumberValidator('SE')]],
      email: ['', [Validators.email]],
      active: [true],
      roles: [[{ id: '15', role: 'USER' }]]
    });

    this.patchFormValue();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private patchFormValue(): void {
    if (this.user && this.form) {
      this.form.patchValue(this.user);
      // this.form.get('roles').setValue(this.user.roles.map(role => role.id));
      // this.form.get('subjects').setValue(this.user.subjects.map(subject => subject.subjectId));
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.updateClient();
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnChanges(): void {
    this.patchFormValue();
  }

  onCancel(): void {
    // this.form.reset();
    this.cancel.emit();
  }

  phoneNumberValidator(regionCode: string = undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false;
      try {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {}

      return validNumber ? null : { wrongNumber: { value: control.value } };
    };
  }

  isIncorrectSSNLength(): boolean {
    const usernameCtrl = this.form.get('username');
    const requiredError = usernameCtrl.hasError('required');
    const minLengthError = usernameCtrl.hasError('minlength');
    const touched = usernameCtrl.touched;

    return (requiredError || minLengthError) && touched;
  }

  isInvalidSSN(): boolean {
    const usernameCtrl = this.form.get('username');
    const invalidSSNError = usernameCtrl.hasError('invalidSSN');
    const requiredError = usernameCtrl.hasError('required');
    const minLengthError = usernameCtrl.hasError('minlength');
    const touched = usernameCtrl.touched;

    return invalidSSNError && !(requiredError || minLengthError) && touched;
  }

  // 197411017978

  private updateClient(): void {
    this.isSaving = true;
    const userDto: UserDTO = {
      ...this.form.value,
      userId: this.user.userId
    };
    userDto.username = userDto.username.replace('-', '');

    this.userService
      .updateUser(this.user.userId, userDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        user => {
          // this.form.reset();
          this.isSaving = false;
          this.clientUpdated.emit(user);
        },
        error => {
          this.isSaving = false;
          // this.cancel.emit(error.error.message);
          this.toastComponent.content = error.error.message;
          this.toastComponent.show();
        }
      );
  }
}
