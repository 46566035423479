import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { mergeMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Only set autorization header on /private/
    if (request.url.indexOf('/public/') > 0 || request.url.indexOf('/bankid/') > 0) {
      return next.handle(request);
    } else {
      const token = this.authService.getToken();
      const clonedReq = this.addToken(request, token);
      return next.handle(clonedReq).pipe(
        tap(
          (event: HttpEvent<any>) => {
            // do something with the response if you want to
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              this.authService.logout();
            }
          }
        )
      );
    }
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any): HttpRequest<any> {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`
        }
      });

      return clone;
    }
    return request;
  }
}
