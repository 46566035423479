import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BeforeOpenCloseMenuEventArgs,
  ClickEventArgs,
  EventArgs,
  MenuAnimationSettingsModel,
  MenuComponent,
  MenuEventArgs,
  OpenCloseMenuEventArgs,
  SidebarComponent
} from '@syncfusion/ej2-angular-navigations';
import { BookingChangesService } from 'booking-sdk';
import { interval, of, Subject, timer } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services';
import { MenuItem } from './menu-item.interface';
import packagejson from '../../../../../package.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('sideMenu') sideMenu: MenuComponent;
  version: string = packagejson.version;

  enableDock: boolean = true;
  width: string = '235px';
  dockSize: string = '42px';
  gestures: boolean = false;
  // mediaQuery: string = '';
  mediaQuery: string = '(min-width: 600px)';
  @ViewChild('sidebarMenuInstance')
  sidebarMenuInstance: SidebarComponent;
  // target: string = '.content';
  target: string = '.main-menu-content';
  // target: string = '.container';
  // target: string = '';
  docked: boolean = false;

  @Output()
  toggleDocking: EventEmitter<boolean> = new EventEmitter();

  menuItems: MenuItem[] = [
    {
      text: 'Ny bokning',
      path: 'new-booking',
      iconCss: 'e-icons client-center-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
    },
    {
      // text: $localize`Kundbokningar`,
      text: 'Kundbokningar',
      path: 'clientcenter',
      iconCss: 'e-icons client-center-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
    },
    {
      text: 'Flytta bokningar',
      path: 'transfer-booking',
      iconCss: 'e-icons client-center-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
    },
    {
      text: 'Överblick',
      path: '',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER'],
      items: [
        {
          text: 'Schema',
          path: 'schedule-overviews/advisors',
          iconCss: 'e-icons schedules-icon',
          roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
        },
        {
          text: 'Bokningar',
          path: 'booking-overviews/specific-booking',
          iconCss: 'e-icons schedules-icon',
          roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
        }
      ]
    },
    {
      text: 'Rapporter',
      path: '',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER'],
      items: [
        {
          text: 'Bokningar',
          path: 'booking-log',
          iconCss: 'e-icons schedules-icon',
          roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
        },
        {
          text: 'SMS',
          path: 'sms-log',
          iconCss: 'e-icons schedules-icon',
          roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
        }
      ]
    },
    {
      text: 'Beläggning resurs',
      path: 'resource-occupancy/specific-resource',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
    },
    {
      text: '',
      separator: true,
      iconCss: '',
      roles: ['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER']
    },

    {
      text: 'Mina bokningar',
      path: 'my-bookings',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADVISOR']
    },
    {
      text: 'Mitt arbetsschema',
      path: 'my-schedule',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADVISOR']
    },
    {
      text: '',
      separator: true,
      iconCss: '',
      roles: ['SUPERADMIN', 'ADVISOR']
    },

    {
      text: 'Administration',
      separator: true,
      iconCss: 'e-icons admin-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Ämnen',
      path: 'subjects',
      iconCss: 'e-icons subjects-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    // {
    //   text: 'Ämne grupper',
    //   path: 'subject-groups',
    //   iconCss: 'e-icons subjects-icon',
    //   roles: ['SUPERADMIN', 'ADMIN']
    // },
    {
      text: 'Platser',
      path: 'locations',
      iconCss: 'e-icons locations-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Taggar',
      path: 'tags',
      iconCss: 'e-icons users-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Resurser',
      path: '',
      iconCss: 'e-icons field-settings-icon',
      roles: ['SUPERADMIN', 'ADMIN'],
      items: [
        // {
        //   text: 'Typ av resurser',
        //   path: 'resource-types',
        //   iconCss: 'e-icons field-settings-icon',
        //   roles: ['SUPERADMIN', 'ADMIN']
        // },
        {
          text: 'Resurser',
          path: 'resources',
          iconCss: 'e-icons field-settings-icon',
          roles: ['SUPERADMIN', 'ADMIN']
        },
        {
          text: 'Resurschema',
          path: 'resource-schedules',
          iconCss: 'e-icons schedules-icon',
          roles: ['SUPERADMIN', 'ADMIN']
        }
      ]
    },
    {
      text: 'Schemamallar',
      path: 'schedule-templates',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Arbetsscheman',
      path: 'schedules',
      iconCss: 'e-icons schedules-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Uppföljning',
      path: 'statistics',
      iconCss: 'e-icons e-chart',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'Användare',
      path: 'users',
      iconCss: 'e-icons users-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    // SRD 22-05-23
    {
      text: 'Inställningar',
      path: 'settings',
      iconCss: 'e-icons settings-icon',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    // SRD 22-05-23
    {
      text: 'Avidentifiering',
      path: 'anonymize-user',
      iconCss: 'e-icons e-delete-3',
      roles: ['SUPERADMIN', 'ADMIN']
    },
    {
      text: 'SMS-kö',
      path: 'sms-queue',
      iconCss: 'e-icons e-sorting-2',
      roles: ['SUPERADMIN']
    },
    {
      text: 'Hjälp',
      path: 'helpitems',
      iconCss: 'e-icons e-circle-info',
      roles: ['SUPERADMIN']
    },
    {
      text: 'Hjälpfiler',
      path: 'helpitem-files',
      iconCss: 'e-icons e-circle-info',
      roles: ['SUPERADMIN']
    },
    {
      text: 'Generera',
      path: 'demo-generate',
      iconCss: 'e-icons e-circle-info',
      roles: ['SUPERADMIN']
    }
  ];

  animationSettings: MenuAnimationSettingsModel = {
    effect: 'FadeIn',
    duration: 800
  };

  private isPolling: boolean;
  private onDestroy$ = new Subject();

  @Output('contentChanged')
  contentChanged = new EventEmitter<string>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private bookingChangesService: BookingChangesService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.menuItems = this.menuItems.filter(item => this.authService.isAuthorized(item.roles));

    // this.contentChanged.emit("Kundbokningar");

    this.startBookingChangePolling();
    this.pauseBookingChangePolling();
    if (this.router.url === '/my-bookings') {
      this.resetChangeCount();
    } else {
      this.resumeBookingChangePolling();
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/my-bookings') {
          this.pauseBookingChangePolling();
          this.resetChangeCount();
        } else {
          this.resumeBookingChangePolling();
        }

        // This will rerender the menu on navigation end events. Required to trigger beforeItemRender event.
        this.menuItems = [...this.menuItems];
      });
  }

  ngAfterViewInit() {
    timer().subscribe(() => {
      const url = this.router.url.substring(1);

      const refreshedURL = this.menuItems.find(item => item.path === url);

      if (refreshedURL) {
        this.contentChanged.emit(refreshedURL.text);
      }
    });
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toolbarCliked(args: ClickEventArgs) {
    // console.log('toolbarCliked - args', args);
    if (args.item.tooltipText == 'Menu') {
      this.sidebarMenuInstance.toggle();
    }
  }

  onSidebarClosed(args: EventArgs): void {
    // console.log('onSidbarClosed - args', args);
    this.docked = true;
    this.toggleDocking.emit(this.docked);
  }

  onSidebarOpened(args: EventArgs): void {
    // console.log('onSidbarOpened - args', args);
    this.docked = false;
    this.toggleDocking.emit(this.docked);
  }

  // created(): void {
  //   console.log("created");
  // }

  onBeforeItemRender(event: MenuEventArgs) {
    // console.log("onBeforeItemRender event", event);

    const menuItem = event.item as MenuItem;

    if (menuItem.text == 'Hjälp' || menuItem.text == 'Hjälpfiler') {
      // console.log("onBeforeItemRender event", event);
      // console.log("environment.hideHelp ", environment.hideHelp);
      if (environment.hideHelp) {
        event.element.classList.add('hidden');
      }
    }

    if (menuItem.text != 'Administration' && menuItem.text != '') {
      const isActive = this.router.isActive(menuItem.path.split('/')[0], {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored'
      });

      if (isActive) {
        event.element.classList.add('active');
      }
    }

    if (menuItem.text == 'Administration') {
      event.element.classList.add('header');
    }

    if (menuItem.text == '') {
      event.element.classList.add('footer');
    }
  }

  // public beforeOpen(args: BeforeOpenCloseMenuEventArgs): void {
  //   // console.log("beforeOpen ", args);
  //   // args.element.classList.remove('hidden');
  //   // console.log(args.element.classList);
  //   // args.element.classList.add('border');
  // }

  // public beforeClose(args: BeforeOpenCloseMenuEventArgs): void {
  //   // console.log("beforeClose ", args);
  //   // args.element.classList.add('border');

  // }

  // public onClose(args: OpenCloseMenuEventArgs): void {
  //   // console.log("onClose ", args);
  //   // console.log("onClose ", args.parentItem.text);

  //   // args.element.parentElement.style.display = "none";

  //   // args.element.classList.add('border');
  //   // args.element.classList.add('hidden');
  //   // // this.sideMenu.refresh();
  //   // this.sideMenu.

  //   // console.log(args.element.classList);
  // }

  // public onOpen(args: OpenCloseMenuEventArgs): void {
  //   // console.log("onOpen ", args);
  // }

  onSelect(event: MenuEventArgs): void {
    // console.log('event ', event);

    const menuItem = event.item as MenuItem;

    if (menuItem.path) {
      this.contentChanged.emit(menuItem.text);
      this.router.navigateByUrl(menuItem.path);
    }
  }

  private startBookingChangePolling() {
    const userId = this.authService.getUser().userId;
    const bookingChangesRequest = this.bookingChangesService.getBookingChanges(
      userId,
      userId,
      false
    );

    // interval(30000)
    timer(0, 30000)
      .pipe(
        filter(() => this.isPolling),
        switchMap(() => bookingChangesRequest),
        map(res => res.totalElements),
        catchError(() => of(0))
      )
      .subscribe(changeCount => {
        const myBookingsMenuItem = this.menuItems.filter(
          menuItem => menuItem.path === 'my-bookings'
        );
        if (myBookingsMenuItem.length > 0 && myBookingsMenuItem[0].count !== changeCount) {
          myBookingsMenuItem[0].count = changeCount;
          this.sideMenu.items = this.menuItems;
        }

        // map can create a memory leak: https://stackoverflow.com/questions/62314588/does-javascript-map-creates-memory-leak
        // this.menuItems = this.menuItems.map(menuItem => {
        //   if (menuItem.path === 'my-bookings') {
        //     return { ...menuItem, count: changeCount };
        //   } else {
        //     return menuItem;
        //   }
        // });
      });
  }

  private resumeBookingChangePolling() {
    this.isPolling = true;
  }

  private pauseBookingChangePolling() {
    this.isPolling = false;
  }

  private resetChangeCount() {
    this.menuItems = this.menuItems.map(menuItem => ({ ...menuItem, count: null }));
    const userId = this.authService.getUser().userId;
    this.bookingChangesService
      .markAllBookingChangesAsSeen(userId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  // private routeAccordingly(url: string) {

  //   switch (url) {
  //     case 'viewNavigate':
  //       break;

  //     case 'dateNavigate':
  //       break;

  //     case 'eventCreated':

  //       break;

  //     case 'eventChanged':

  //       break;

  //     case 'eventRemoved':

  //       break;

  //     default:
  //       break;
  //   }

  // }
}
