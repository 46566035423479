import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, RadioButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { AccordionModule, MenuModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ProgressBarAllModule } from '@syncfusion/ej2-angular-progressbar';
import {
  ComboBoxModule,
  ListBoxModule,
  MultiSelectModule
} from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import {
  CalendarModule,
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule
} from '@syncfusion/ej2-angular-calendars';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgxMaskModule } from 'ngx-mask';

import { SubjectSelectorComponent } from './schedule/subject-selector/subject-selector.component';
import { LocationSelectorComponent } from './schedule/location-selector/location-selector.component';
import { UserInfoBlockComponent } from './schedule/user-info-block/user-info-block.component';
import { BookingEditorComponent } from './schedule/booking-editor/booking-editor.component';
import { AdvisorSelectorComponent } from './schedule/advisor-selector/advisor-selector.component';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { UpdateClientComponent } from './customer-selector/update-client/update-client.component';
import { CreateClientComponent } from './customer-selector/create-client/create-client.component';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SmsConfirmComponent } from './sms-confirm/sms-confirm.component';
import { ShowSmsComponent } from './sms-confirm/show-sms/show-sms.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ScheduleTypeSelectorComponent } from './schedule/schedule-type-selector/schedule-type-selector.component';
import { ScheduleEditorComponent } from './schedule/schedule-editor/schedule-editor.component';
import { ResourceScheduleEditorComponent } from './schedule/resource-schedule-editor/resource-schedule-editor.component';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { SafePipe } from './pipes/safe.pipe';
import { NextAvailableBlockComponent } from './next-available-block/next-available-block.component';

@NgModule({
  declarations: [
    SubjectSelectorComponent,
    LocationSelectorComponent,
    AdvisorSelectorComponent,
    ScheduleTypeSelectorComponent,
    UserInfoBlockComponent,
    BookingEditorComponent,
    ScheduleEditorComponent,
    ResourceScheduleEditorComponent,
    CustomerSelectorComponent,
    UpdateClientComponent,
    CreateClientComponent,
    CustomerInfoComponent,
    SmsConfirmComponent,
    ShowSmsComponent,
    SafePipe,
    NextAvailableBlockComponent
  ],
  imports: [
    DropDownListModule,
    RichTextEditorModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    DateTimePickerModule,
    ProgressBarAllModule,
    ToastModule,
    DialogModule,
    ComboBoxModule,
    ListViewModule,
    ButtonModule,
    NumericTextBoxModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ColorPickerModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    GridAllModule,
    MenuModule,
    ButtonModule,
    DialogModule,
    ProgressBarAllModule,
    MultiSelectModule,
    DropDownListModule,
    ScheduleModule,
    DatePickerModule,
    TimePickerModule,
    ToastModule,
    RadioButtonModule,
    CalendarModule,
    ListBoxModule,
    NgxMaskModule,
    TreeGridModule,
    RichTextEditorModule,
    TabModule,
    CheckBoxModule,
    AccordionModule,
    SubjectSelectorComponent,
    LocationSelectorComponent,
    AdvisorSelectorComponent,
    ScheduleTypeSelectorComponent,
    UserInfoBlockComponent,
    BookingEditorComponent,
    ScheduleEditorComponent,
    ResourceScheduleEditorComponent,
    CustomerSelectorComponent,
    UpdateClientComponent,
    CreateClientComponent,
    ListViewModule,
    CustomerInfoComponent,
    SmsConfirmComponent,
    ShowSmsComponent,
    ColorPickerModule,
    SafePipe
  ]
})
export class SharedModule {}
