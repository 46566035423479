<div fxLayout="row">
  <ejs-toast #InfoToast class="InfoToast" [position]="infoToastPosition">
    <ng-template #title>
      <div>Varning</div>
    </ng-template>
    <ng-template #content>
      <div>Kan inte göra det</div>
    </ng-template>
  </ejs-toast>

  <!-- fxFlex="80" -->
  <div fxFlex="80" fxLayout="column" fxLayoutAlign="start" style="padding-bottom: 10px">
    <ejs-combobox
      #cbcustomer
      id="cbcustomer"
      [dataSource]="customers"
      [fields]="customerFields"
      [allowFiltering]="true"
      [allowCustom]="false"
      (change)="onCustomerCBChange($event)"
      (filtering)="onCCBFiltering($event)"
      (focus)="onCCBFocus($event)"
      (actionComplete)="onCCBComplete($event)"
      (keydown)="onKeyDown($event)"
      placeholder="Sök  &#8212;  kund personnummer ( ÅÅÅÅMMDD-XXXX ) *"
    >
    </ejs-combobox>

    <!-- <ejs-dropdownlist id='ddlcustomer' [dataSource]='customers' [fields]='customerFields' 
        placeholder = 'VÄLJ KUND'>
        </ejs-dropdownlist> -->

    <ejs-dropdownlist
      #ddlsubject
      id="ddlsubject"
      [dataSource]="subjects"
      [fields]="subjectFields"
      (change)="onSubjectChange($event)"
      placeholder="Välj  &#8212;  ämne *"
    >
      <ng-template #headerTemplate>
        <div class="head" style="width: 100%; background-color: #e2e2e2; text-align: right">
          <button class="clear" ejs-button (click)="clearSubjects()">Rensa</button>
        </div>
      </ng-template>
    </ejs-dropdownlist>

    <ejs-dropdownlist
      #ddllocation
      id="ddllocation"
      [dataSource]="locations"
      [fields]="locationFields"
      (change)="onLocationChange($event)"
      placeholder="Välj  &#8212;  plats"
    >
    </ejs-dropdownlist>
  </div>
  <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-around center">
    <button
      class="clear"
      ejs-button
      [disabled]="invalidSSN || !customerExists"
      (click)="newBooking()"
    >
      Ny/ändra bokning
    </button>
    <button
      class="clear"
      ejs-button
      [disabled]="invalidSSN || customerExists"
      (click)="createClient()"
    >
      Ny kund
    </button>
    <!-- <button class="create-client-button" ejs-button (click)="createClient()">Ny kund</button> -->
  </div>
</div>

<ejs-toast #Toast [position]="toastPosition">
  <ng-template #title>
    <div>Bokning fel</div>
  </ng-template>
  <ng-template #content>
    <div>Kan inte göra det</div>
  </ng-template>
</ejs-toast>

<ejs-dialog
  #CreateClientDialog
  [position]="dialogPosition"
  [visible]="false"
  [closeOnEscape]="false"
  isModal="true"
  width="550px"
>
  <app-create-client
    [hidden]="!CreateClientDialog.visible"
    [ssn]="ssn"
    (client-created)="onClientCreated($event)"
    (cancel)="onClientCancelled($event)"
  ></app-create-client>
</ejs-dialog>

<ejs-dialog
  #UpdateClientDialog
  [position]="dialogPosition"
  [visible]="false"
  [closeOnEscape]="false"
  isModal="true"
  width="550px"
>
  <app-update-client
    [hidden]="!UpdateClientDialog.visible"
    (client-updated)="onClientUpdated()"
    [user]="selCustomer"
    (cancel)="updateClientDialog.hide()"
  ></app-update-client>
</ejs-dialog>
