import { FormGroup } from '@angular/forms';

export function isValidSwedishSSN(control: FormGroup): { [key: string]: boolean } | null {
  if (!control.value) {
    return { invalidSSN: true };
  }

  const pin = control.value
    .replace(/\D/g, '') // strip out all but digits
    .split('') // convert string to array
    .reverse() // reverse order for Luhn
    .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

  // verify we got 10 digits, otherwise it is invalid
  if (pin.length != 10) {
    return { invalidSSN: true };
  }

  const sum = pin
    // convert to number
    .map((n: number) => +n)
    // perform arithmetic and return sum
    .reduce((previous: number, current: number, index: number) => {
      // multiply every other number with two
      if (index % 2) current *= 2;
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) current -= 9;
      // sum it up
      return previous + current;
    });

  // sum must be divisible by 10
  if (0 === sum % 10) {
    return null;
  } else {
    return { invalidSSN: true };
  }
}
