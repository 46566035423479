import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TimelineMonthService } from '@syncfusion/ej2-angular-schedule';
import { UserDTO } from 'booking-sdk';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'current-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router) {}

  setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  setUser(user: UserDTO): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getUser(): UserDTO {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  isAuthenticated(): boolean {
    // Make sure user has a relevant role
    return (
      !!this.getToken() &&
      !!this.getUser() &&
      this.isAuthorized(['SUPERADMIN', 'ADMIN', 'ADVISOR', 'CLIENT_CENTER'])
    );
  }

  isAuthorized(roles: string[]): boolean {
    return roles.filter(role => this.hasRole(role)).length > 0;
  }

  hasRole(role: string): boolean {
    return !!this.getUser()?.roles?.find(r => r.role === role);
  }

  logout(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    this.router.navigateByUrl('/login');
  }
}
