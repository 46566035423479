<div class="navbar" fxLayout="column">
  <div class="brand-border"></div>

  <div fxFlex="auto" fxFlexAlign="center start" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlexAlign="center" class="brand-image" fxLayout="column" fxLayoutAlign="center">
      <a fxFlex="auto" routerLink="/" fxLayout="column" fxLayoutAlign="center">
        <img fxFlex="auto" src="/assets/logo.png" />
      </a>
    </div>

    <div class="header-content">
      <h1>{{ content }}</h1>
    </div>

    <div>
      <button
        ejs-dropdownbutton
        [items]="dropdownItems"
        [content]="user?.firstname + ' ' + user?.lastname"
        cssClass="dropdown-button"
        iconCss="e-ddb-icons e-profile"
        (select)="onSelect($event)"
      ></button>

      <!-- SRD 22-05-23 -->
      <button
        *ngIf="!isMobileDevice"
        class="help-button"
        iconCss="e-icons e-medium e-circle-info"
        ejs-button
        (click)="toggleHelpSidebar()"
      >
        Hjälp
      </button>
    </div>
  </div>
</div>
