import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdvisorDTO } from 'booking-sdk';

export interface AdvisorDTOExtended extends AdvisorDTO {
  fullName?: string;
}

@Component({
  selector: 'app-advisor-selector',
  templateUrl: './advisor-selector.component.html',
  styleUrls: ['./advisor-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AdvisorSelectorComponent
    }
  ]
})
export class AdvisorSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  advisors: AdvisorDTOExtended[];

  @Input()
  advisor: AdvisorDTOExtended;

  // @Output()
  // valueChange: EventEmitter<AdvisorDTO> = new EventEmitter<AdvisorDTO>();
  onChange = advisor => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  advisorFields: Object = { text: 'fullName', value: 'userId' };
  currentAdvisorId: number;
  currentAdvisors: AdvisorDTOExtended[];

  constructor() {}

  writeValue(advisor: AdvisorDTOExtended) {
    this.advisor = advisor;
    if (this.advisors && this.advisor) {
      this.updateList();
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.advisors && changes.advisors.currentValue && this.advisor) {
      this.updateList();
    }
  }

  private updateList() {
    // Copy original list
    this.currentAdvisors = JSON.parse(JSON.stringify(this.advisors));
    this.currentAdvisors.forEach(
      advisor => (advisor.fullName = advisor?.firstname + ' ' + advisor?.lastname)
    );

    if (this.advisor) {
      this.currentAdvisorId = this.advisor.userId;

      // Add subject from booking to list, if it doesn't already exists
      if (!this.currentAdvisors.find(a => a.userId === this.advisor.userId)) {
        this.currentAdvisors.push({
          ...this.advisor,
          fullName: this.advisor?.firstname + ' ' + this.advisor?.lastname
        });
      }
    }
  }

  onAdvisorChange(args) {
    // this.valueChange.emit(args.itemData);
    this.markAsTouched();
    this.advisor = args.itemData;
    this.onChange(this.advisor);
  }
}
