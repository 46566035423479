import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiModule, BASE_PATH } from 'booking-sdk';
import { NgxMaskModule } from 'ngx-mask';

import { environment } from 'src/environments/environment';
import { TokenInterceptor } from './interceptors/token.interceptor';
import '@angular/localize/init';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, ApiModule, NgxMaskModule.forRoot()],
  providers: [
    { provide: BASE_PATH, useValue: environment.bookingServerUrl },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
