<div *ngIf="user" class="user-block">
  <label for="Customer">Kund</label>
  <input
    id="Customer"
    class="e-input"
    type="text"
    [value]="user.firstname + ' ' + user.lastname + ': ' + formatUsername(user.username)"
    disabled
  /><br />
  <input
    class="e-input"
    type="text"
    [value]="'Telefon: ' + (user.phone ? user.phone : '( saknas )')"
    disabled
  /><br />
  <input
    class="e-input"
    type="text"
    [value]="'E-post: ' + (user.email ? user.email : '( saknas )')"
    disabled
  />
</div>
