<div tabindex="0" class="e-card1 rcorners-fill" id="basic1">
  <div class="e-card-header e-card-header-user">
    <div class="e-card-header-caption">
      <div class="e-card-title">
        <div class="userInfoHeader">
          {{ customer.firstname + ' ' + customer.lastname }}
        </div>
        <hr class="sep" />
      </div>
    </div>
  </div>

  <div class="e-card-separator"></div>

  <div class="e-card-content">
    <div class="userInfoHeader2"><strong>Telefon</strong></div>
    <div class="infobox">{{ customer.phone }}</div>
  </div>

  <div class="e-card-separator" style="padding-top: 20px"></div>

  <div class="e-card-content">
    <div class="userInfoHeader2"><strong>E-post</strong></div>
    <div class="infobox">{{ customer.email }}</div>
  </div>

  <div class="e-card-separator" style="padding-top: 20px"></div>

  <hr class="sep" />
  <div class="e-card-actions e-card-vertical" style="padding-bottom: 20px" fxLayoutAlign="end">
    <button class="update-client-button" ejs-button (click)="updateClient()">Redigera</button>
  </div>
</div>

<!-- 197411017978 -->

<div class="booking-container">
  <div *ngIf="customer">
    <div tabindex="0" class="e-card2 rcorners-fill" id="basic2">
      <div class="e-card-header e-card-header-booking">
        <div class="e-card-header-caption">
          <div class="e-card-title">
            <div class="bookingInfoHeader">Bokade möten</div>
          </div>
        </div>
      </div>
      <div class="e-card-content">
        <div *ngIf="selBookingInfo.length > 0">
          <ejs-listview
            id="booking-list"
            class="booking-list"
            [dataSource]="selBookingInfo"
            headerTitle="Bokade möten"
            cssClass="e-list-template e-list-wrapper"
            [showHeader]="false"
            [showIcon]="true"
            sortOrder="Ascending"
            [fields]="selBookingFields"
            (select)="onSelectBookingInfo($event)"
          >
          </ejs-listview>
        </div>
      </div>
    </div>

    <!-- 197411017978 -->

    <div
      tabindex="0"
      class="rcorners-fill e-card3"
      [class.e-card3-hidden]="!isPreviousShown"
      id="basic3"
    >
      <div class="e-card-header e-card-header-booking">
        <div class="e-card-header-caption">
          <div class="e-card-title" fxLayout="row" fxLayoutAlign="center start">
            <!-- <button (click)="togglePreviousShow()" class="expand">+</button> -->
            <button
              #togglePrevious
              ejs-button
              (click)="isPreviousShown = !isPreviousShown"
              cssClass="previous"
            >
              +
            </button>
            <div class="bookingInfoHeader">Tidigare möten</div>
          </div>
        </div>
      </div>
      <div class="e-card-content prevBookings">
        <div *ngIf="selOldBookingInfo.length > 0">
          <ejs-listview
            *ngIf="isPreviousShown"
            id="old-booking-list"
            class="old-booking-list"
            [dataSource]="selOldBookingInfo"
            headerTitle="Bokade möten"
            cssClass="e-list-template e-list-wrapper"
            [showHeader]="false"
            [showIcon]="true"
            sortOrder="Descending"
            [fields]="selBookingFields"
            (select)="onSelectBookingInfo($event)"
          ></ejs-listview>
        </div>
      </div>
    </div>
  </div>
</div>

<ejs-dialog
  #UpdateClientDialog
  [position]="dialogPosition"
  [visible]="false"
  [closeOnEscape]="false"
  isModal="true"
  width="550px"
>
  <app-update-client
    [hidden]="!UpdateClientDialog.visible"
    (client-updated)="onClientUpdated($event)"
    [user]="customer"
    (cancel)="updateClientDialog.hide()"
  ></app-update-client>
</ejs-dialog>
