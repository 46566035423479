import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsernameService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    let headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa(environment.clientId + ':' + environment.clientSecret)
    });
    const options = {
      headers
    };

    let body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);

    return this.http.post(environment.bookingServerUrl + `/oauth/token`, body, options);
  }
}
